import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/customer/Navbar";
import { GlobalContext } from "../../context/GlobalContext";
import MainLayout from "../../layouts/MainLayout";
import CategoryProduct from "../../components/customer/CategoryProduct";
import Product from "../../components/customer/Product";
import Profile from "../../components/customer/Profile";
import ProductDiscount from "../../components/customer/ProductDiscount";
import Slider from "../../components/bannerPromo/Slider";
import Bundle2 from "../../components/customer/Bundle2";
import Popup from "../../components/reusableComponents/Popup";

const Home = () => {
  const { navbar, setNavbar } = useContext(GlobalContext);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setShowPopup(true);
  }, [setShowPopup]);
  return (
    <MainLayout>
      <CategoryProduct />
      <Product />
      <Profile />
      {/* <ProductDiscount /> */}
      <Slider />
      <Bundle2 />
      <Popup show={showPopup} onClose={() => setShowPopup(false)} />
    </MainLayout>
  );
};

export default Home;
